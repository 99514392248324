
body, html {
  height: 100%;
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f22323; 
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1; /* This will push the footer to the bottom */
  padding-bottom: 50px; /* Adjust padding to prevent overlap */
  position: relative;
}

h1, h2 {
  color: black;
}

img {
  width: 100%;
  height: auto;
  margin-top: 20px;
}

ul {
  padding-left: 0;
  list-style-type: none;
  text-align: left;
  display: inline-block;
  text-align: left;
  padding-left: 20px;
}

/* App Header */
.App-header {
  position: relative;
  width: 100%;
  height: 300px;
  overflow: hidden;
}

.header-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.logo-image {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  height: auto;
}

.navbar {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 0, 0, 0.7);
  padding: 10px 20px;
  border-radius: 10px;
}

nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  gap: 20px;
}

nav ul li {
  margin: 0;
}

nav ul li a {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

nav ul li a:hover {
  text-decoration: underline;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 100px;
  margin-top: 20px;
}

.card {
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  padding: 15px 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  cursor: pointer;
  text-align: center;
  width: calc(60% - 40px);
  max-width: 300px;
  margin: 10px; 
}

@media (min-width: 768px) {
  .card-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

.card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  transform: translateY(-5px);
}

.mission-card {
  background: linear-gradient(to right, #ff9999, #ffcc99);
  color: #333;
  font-style: italic;
  padding: 30px;
}

.mission-card p {
  margin: 0;
  line-height: 1.6;
  font-size: 1.2em;
}

.images-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.images-container img {
  width: 100%;
  max-width: 200px;
  height: auto;
  border-radius: 10px;
}

.images-card {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px; /* Add more space between images */
  text-align: center;
}

.image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.images-card img {
  width: 100%;
  max-width: 150px; /* Smaller size for side-by-side layout */
  height: auto;
  border-radius: 10px;
  transition: transform 0.3s ease; /* Smooth transition for hover effect */
  margin-top: 10px; /* Add space between text and image */
}

.images-card img:hover {
  transform: scale(1.05); /* Slightly enlarge image on hover */
}

/* Form Styles */
form div {
  margin-bottom: 10px;
}

form label {
  display: block;
  margin-bottom: 5px;
  color: black;
  text-align: left;
}

form input,
form textarea {
  width: calc(100% - 20px); /* Adjust for padding */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box; /* Include padding in width calculation */
  margin-left: 10px; /* Add margin to prevent touching the edge of the card */
}

form button {
  background-color: red;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: block;
  margin: 0 auto;
}

form button:hover {
  background-color: darkred;
}

/* Image Styles */
.donate-image {
  width: 100%;
  max-width: 150px;
  border-radius: 10px;
  transition: transform 0.3s ease;
  margin-top: 10px;
}

.donate-image:hover {
  transform: scale(1.05);
}

@media (max-width: 600px) {
  .card {
    width: calc(100% - 20px); 
    margin: 10px; 
    padding: 20px; 
  }

  form input,
  form textarea {
    width: calc(100% - 16px);
    padding: 8px;
  }

  form button {
    padding: 8px 16px;
  }

  .images-container img {
    max-width: 100px;
  }

  .images-card {
    flex-direction: column; 
  }

  .images-card img {
    max-width: 100px;
  }

  .donate-image {
    max-width: 100px;
  }

  /* Adjust card styles for mobile */
  .card {
    width: calc(100% - 20px);
    margin: 10px;
    padding: 15px;
  }

  .privacy-policy-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
}

.privacy-policy-container h1 {
    font-size: 24px;
    margin-bottom: 20px;
}

.privacy-policy-container h2 {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.privacy-policy-container p {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 15px;
}

.privacy-policy-container ul {
    list-style-type: disc;
    margin-left: 20px;
}  
}
