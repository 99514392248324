.footer {
    background-color: rgba(0, 0, 0, 0.8); /* Dark semi-transparent background */
    color: white;
    text-align: center;
    padding: 10px 0;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
}

.footer p {
    margin: 0;
    font-size: 14px;
}

.footer-link {
    color: #00b4db;
    text-decoration: none;
    margin-left: 10px;
}

.footer-link:hover {
    text-decoration: underline;
}
